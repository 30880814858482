// extracted by mini-css-extract-plugin
export var mobileNavigationOverlay = "mobileNavigationOverlay-module--mobile-navigation-overlay--vmgJu";
export var mobileHeader = "mobileNavigationOverlay-module--mobileHeader--4dxrq";
export var logoImg = "mobileNavigationOverlay-module--logoImg--f6wzP";
export var navigationList = "mobileNavigationOverlay-module--navigationList--Nella";
export var menuItem = "mobileNavigationOverlay-module--menuItem--927Iv";
export var menuItemBtn = "mobileNavigationOverlay-module--menuItemBtn--pMHur";
export var ctaBtn = "mobileNavigationOverlay-module--ctaBtn--t1xNX";
export var subItem = "mobileNavigationOverlay-module--subItem--4tzU2";
export var menuSeparator = "mobileNavigationOverlay-module--menuSeparator--nY1aT";
export var bottomPart = "mobileNavigationOverlay-module--bottomPart--pkuf5";
export var icon = "mobileNavigationOverlay-module--icon--ibCVM";
export var backBtn = "mobileNavigationOverlay-module--backBtn--GY2ZV";
export var isMenuOpen = "mobileNavigationOverlay-module--is-menu-open--XgMG3";