import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons'
import { useRemoveItemFromCart } from '@jamal./gatsby-theme-shopify-manager';

const RemoveCartItem = ({ removeItemFromBonusArray, itemId, productId }) => {

  const removeItemFromCart = useRemoveItemFromCart()

  async function removeItem(e) {
    e.preventDefault();
    try {
      await removeItemFromCart(itemId);
      removeItemFromBonusArray(productId)
    } catch {
      alert("There was a problem removing that item. Please try again.");
    }
  }

  return (
    <>
      <a href="/#" role="button" tabIndex={0} className="position-absolute top right z-2 display-flex align-items-center justify-content-center trash-icon removeCartItem" onClick={removeItem}>
        <FontAwesomeIcon icon={faTrashAlt} className="f-17 grey o-5" />
      </a>
    </>
  )
}

export default RemoveCartItem
