import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPlus,
  faMinus,
} from '@fortawesome/free-solid-svg-icons'
import { useUpdateItemQuantity } from '@jamal./gatsby-theme-shopify-manager';

const QuantityStepper = ({ itemId, quantity }) => {

  const updateItemQuantity = useUpdateItemQuantity()

  async function increaseQuantity() {
    try {
      await updateItemQuantity(itemId, quantity + 1);
    } catch {
      alert("There was a problem updating that item's quantity. Please try again.");
    }
  }

  async function decreaseQuantity() {
    if (quantity - 1 > 0) {
      try {
        await updateItemQuantity(itemId, quantity - 1);
      } catch {
        alert("There was a problem updating that item's quantity. Please try again.");
      }
    }
  }

  return (
    <div className="d-flex align-items-center justify-content-start quantities">
      <div className="d-block">
        <button data-type="minus" type="button" className="btn-number bg-white d-flex align-items-center justify-content-center m-0 decreaseQuantity" onClick={decreaseQuantity}>
          <FontAwesomeIcon icon={faMinus} className={`f-11 text-dark ${quantity === 1 ? 'o-2 disabled' : ''}`} />
        </button>
      </div>
      <input id={itemId} name={itemId} max="20" min="0" type="text" className="text-center border-0 f-17 mb-0 d-inline-block p-0 form-control input-number d-block mb-0 cartCountSelector" value={quantity} disabled />
      <div className="d-block">
        <button data-type="minus" type="button" className="btn-number bg-white d-flex align-items-center justify-content-center m-0 increaseQuantity" onClick={increaseQuantity}>
          <FontAwesomeIcon icon={faPlus} className="f-11 text-dark" />
        </button>
      </div>
    </div>
  )
}

export default QuantityStepper
