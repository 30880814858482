import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./mobileNavigationOverlay.module.scss"

export default function MobileNavigationOverlay(props) {

  const data = useStaticQuery(graphql`
    query {
      logoGrey: file(relativePath: { eq: "drops-logo-black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 76, height: 30, placeholder: NONE)
        }
      },
    }
  `)

  return (
    <div id={styles.mobileNavigationOverlay} className={`bg-white z-4 position-fixed p-fill w-100 ${props.showMobileNavi ? styles.isMenuOpen : ""}`}>
      <div className={styles.mobileHeader}>
        <GatsbyImage loading="eager" className={styles.logoImg} style={{maxHeight: '40px'}} image={data.logoGrey.childImageSharp.gatsbyImageData} alt="Drops Logo Grey" />
      </div>
      <div className={styles.navigationList}>
        <div className={styles.menuItem}>
          <a href="/products/probierpaket" className="fw-bold">Probierpaket</a>
        </div>
        <div className={styles.menuItem}>
          <a href="/products/monatspaket" className="fw-bold">Monatspaket</a>
        </div>
        <div className={styles.menuItem}>
          <a href="/products/starterkit">Starterkit</a>
        </div>
        <div className={styles.menuItem}>
          <a href="/products/zubehoerbox">Zubehörbox</a>
        </div>
        <div className={styles.menuItemBtn}>
          <a className={styles.ctaBtn} href="/products/starterkit">Jetzt loslegen</a>
        </div>
        <div className={`${styles.menuSeparator} ${styles.menuItem}`}></div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/pages/aboutus">Über Uns</a>
        </div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/reviews">Bewertungen</a>
        </div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/pages/imprint">Impressum</a>
        </div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/pages/privacy-policy">Datenschutz</a>
        </div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/pages/agb">AGB</a>
        </div>
        <div className={`${styles.menuItem} ${styles.subItem}`}>
          <a href="/pages/disclaimer">Widerruf</a>
        </div>
      </div>
      <div role="button" tabIndex={0} className={`position-absolute ${styles.backBtn}`} onClick={props.toggleMobile} onKeyDown={null}>
        <svg xmlns="http://www.w3.org/2000/svg">
          <path d="M17 4l-8 8 8 8M1 4l8 8-8 8" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2"></path>
        </svg>
      </div>
    </div>
  )
}
