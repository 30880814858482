import React, { useState, useEffect } from 'react';

import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from './bottomBar.module.scss'

import { useAddItemsToCart } from '@jamal./gatsby-theme-shopify-manager';

import useIsClient from '../../useIsClient';

export default function BottomBar(props) {

  const { isClient, key } = useIsClient();

  const addItemsToCart = useAddItemsToCart();

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setOffset(window.scrollY);
    };

    document.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [offset]);

  async function handleAddToCart(event) {
    event.preventDefault()
    const variantId = props.activeVariant.shopifyId;
    const quantity = 1;

    const items = [
      {
        variantId: variantId,
        quantity: quantity,
      },
    ];

    if (props.containerItems !== undefined) {
      props.containerItems.forEach(function (item, index) {
        items.push({
          variantId: item,
          quantity: 1
        })
      });
    }

    try {
      // await addItemsToCart(items);
      alert('Produkt derzeit nicht verfügbar.');
    } catch {
      alert('There was a problem adding that item to your cart.');
    }

    // document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');
    // document.body.classList.add("body-lock");
    // document.getElementById("cartOverlay").classList.toggle('is-cart-open');
  }

  if ( !isClient ) return null;

  return (
    <div key={key} id={styles.productBottomBar} className={`position-fixed bottom bg-white w-100 productBottomBar hidden-sm-down show ${offset >= 1000 ? styles.isScrolled : ''}`}>
  		<div className="d-block">
  			<div className="w-100 container p-0 mw-1">
  				<div className="d-flex align-items-center justify-content-between px-0 pr-3 py-0">
  					<div className="line-height-small align-items-center d-sm-flex">
  						<div className="mr-3 d-block">
                <GatsbyImage id="bottomBarImg" className="d-block" image={props.activeVariant.productImg.childImageSharp.gatsbyImageData} style={{objectFit: 'cover', width: '90px', height: '90px'}} alt={props.activeVariant.productTitle} />
  						</div>
  						<div className="w-100 d-block">
  							<span id="bottomBarTitle" className="font-weight-bold d-block f-20 text-left text-black">{props.activeVariant.productTitle}</span>
                {props.activeVariant.title !== 'Default Title' ?
                  <span className="f-17 darkgrey d-block">{props.activeVariant.title}</span>
                : null }
  						</div>
  					</div>
  					<div className="d-flex align-items-center">
  						<div className="mr-3 w-100 hidden-sm-down text-right line-height-smaller">
                {(props.activeVariant.compareAtPrice !== null && props.activeVariant.compareAtPrice !== '0.00') &&
                  <s className="grey font-weight-lightbold mr-2 small">{props.activeVariant.compareAtPrice.replace('.', ',')}€</s>
                }
  							<span className="font-weight-bold">{localStorage.getItem('discount') === 'CODE40' && props.activeVariant.title.includes('4 Monate') ? parseFloat(props.activeVariant.price*0.7513).toFixed(2).toString().replace('.', ',') : props.activeVariant.price.toFixed(2).toString().replace('.', ',')}€</span><br />
  							<span className={`small grey ${props.activeVariant.basePrice === 0 ? 'd-none' : 'd-block' }`} style={{fontSize: '11px'}}>{localStorage.getItem('discount') === 'CODE40' && props.activeVariant.title.includes('4 Monate') ? (parseFloat(props.activeVariant.price*0.7513)/248).toFixed(2) : props.activeVariant.basePrice.toString().replace('.', ',')} € / Stück</span>
  						</div>
  						<a href="#atc" className="btn btn-primary btn-lg font-weight-bold mint f-19 mw-400 px-5 py-3 atc-btn bottomBarATC" onClick={handleAddToCart}>
  							<div className="px-sm-3 px-lg-4" style={{whiteSpace: 'nowrap'}}>Jetzt kaufen</div>
  						</a>
  					</div>
  				</div>
  			</div>
  		</div>
  	</div>
  )
}
