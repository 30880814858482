import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./cartOverlay.module.scss"
import QuantityStepper from './quantityStepper'
import RemoveCartItem from './removeCartItem'
import CartUpsellItem from './cartUpsellItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  useCartCount,
  useCart,
  useCartItems,
  useCheckoutUrl,
  useAddItemToCart
} from '@jamal./gatsby-theme-shopify-manager';
import {
  faAngleLeft,
  faLock
} from '@fortawesome/free-solid-svg-icons'

import SwiperCore, { Navigation } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css'
import "swiper/css/navigation"

import useIsClient from '../../useIsClient';

// install Swiper modules
SwiperCore.use([Navigation]);

export default function CartOverlay() {

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct(
        sort: {fields: id, order: ASC}
        filter: {handle: {in: ["f-f-probierpaket-fur-14-tage"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 100, height: 100, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            descriptionHtml
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
              metafields {
                id
                key
                value
              }
              title
              shopifyId
              selectedOptions {
                name
                value
              }
              price
              compareAtPrice
            }
            storefrontId
          }
        }
      },
      singleItems: allShopifyProduct(
        sort: {fields: [title], order: DESC}
        filter: {id: {in: ["5c8de43d-0bca-512a-ab7e-55ef35f6c8b2", "952c51df-47df-5ff8-ae0c-e412324ad325", "4342aea1-d207-59b0-a075-55c871b90bcc", "441b7ae2-5e6b-51e4-b27a-3f984ba023c0", "ed5e00b9-487e-50ee-ba80-bd59a3bce9c6", "be8cd5de-df0f-5507-b156-fba590d2f8f8"]}}
      ) {
        edges {
          node {
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          title
          images {
            originalSrc
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 95, height: 90, placeholder: NONE)
              }
            }
          }
          id
          storefrontId
        }
      },
      wFluorid: file(relativePath: { eq: "probierpaket.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 100, height: 100, placeholder: NONE)
        }
      },
    }
  `)

  const { isClient, key } = useIsClient();

  const cartCount = useCartCount()
  const cart = useCart()
  const cartItems = useCartItems()
  const checkoutUrl = useCheckoutUrl()
  const addItemToCart = useAddItemToCart();
  const [allUpsellItemsAdded, setAllUpsellItemsAdded] = useState(false);
  const [allBonusItems, setAllBonusItems] = useState([]);
  const [hasFFinCart, setHasFFinCart] = useState(false)
  var cartVariantIds = [];

  // Slider params definition
  let params = {
    loop: true,
    spaceBetween: 0,
  };

  function handleHideCart() {
    document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');
    document.getElementById("cartOverlay").classList.toggle('is-cart-open');
    document.body.classList.remove("body-lock");
  }

  async function handleAddToCart(event) {
    event.preventDefault()
    const variantId = "gid://shopify/ProductVariant/39438135656631";
    const quantity = 1;

    try {
      // await addItemToCart(variantId, quantity);
      alert('Produkt derzeit nicht verfügbar.');
    } catch (error) {
      alert(error);
    }
  }

  async function handleAddToCartBonusItem(event) {
    event.preventDefault()

    let productId = event.currentTarget.dataset.productid;

    try {
      // await addItemToCart(event.currentTarget.dataset.id, 1, [{
      //   key: '_type',
      //   value: 'upsell',
      // }]);
      // setAllBonusItems([...allBonusItems, productId]);
      alert('Produkt derzeit nicht verfügbar.');
    } catch (error) {
      alert(error);
    }
  }

  function handleCheckoutBtn(event) {
    event.preventDefault();
    window.location.href = checkoutUrl.replace('toothpaste-drops.myshopify', 'shop.toothpastedrops');
  }

  function handleZubehoerClick(event) {
    window.location.href = "/products/zubehoerbox"
  }

  function removeItemFromBonusArray(shopifyId) {
    setAllBonusItems(allBonusItems.filter(item => item !== shopifyId));
  }

  if (cartItems.length > 0) {
    var upsellCountInCart = 0;
    var hasFF = false
    data.allShopifyProduct.edges.forEach((upsellItem) => {
      cartItems.forEach((lineItem) => {
        if (upsellItem.node.storefrontId === lineItem.variant.product.id) {
          upsellCountInCart++;
        }
        cartVariantIds.push(lineItem.variant.product.id);
        if (lineItem.variant.product.id === "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY5NTc2MjM2MDc0Nzk=") {
          hasFF = true
        }
      })
    })
    cartVariantIds = [...new Set(cartVariantIds)];

    if (hasFF) {
      setHasFFinCart(true)
    } else {
      setHasFFinCart(false)
    }

    if (data.allShopifyProduct.edges.length === upsellCountInCart) {
      setAllUpsellItemsAdded(true)
    } else {
      setAllUpsellItemsAdded(false)
    }

  }

  useEffect(() => {
    // check if additional bonus items are in cart
    var bonusItems = []
    data.singleItems.nodes.forEach((bonusItem) => {
      cartItems.forEach((lineItem) => {
        if (bonusItem.storefrontId === lineItem.variant.product.id) {
          bonusItems.push(bonusItem.storefrontId);
        }
      })
    })
    setAllBonusItems(bonusItems)
  }, []);


  if ( !isClient ) return null;

  return (
    <div id="cartOverlay" className="bg-white z-4 position-fixed p-fill w-100" key={key}>
  		<div data-notes-enable data-section-id data-section-type="cart-template" id="CartSection">
  			<form action={checkoutUrl} id="shoppingCart" method="post" className="p-0 m-0 cart w-100 d-flex flex-column">
  				<div id="cartNav" className="w-100 d-flex align-items-center bg-white z-2 border-bottom">
  					<div role="button" tabIndex={0} className="menu-back-button z-2" onClick={handleHideCart} onKeyDown={null}>
              <FontAwesomeIcon icon={faAngleLeft} />
  					</div>
  					<div className="p-2 d-flex align-items-center justify-content-center w-100 position-absolute p-fill">
  						<div className="text-center w-100 line-height-smaller d-block">
  							<span className="f-17 text-uppercase line-height-smaller d-block font-weight-bold">Dein Warenkorb</span>
  						</div>
  					</div>
  				</div>
  				<div id="cartContent" className="d-block h-auto align-self-stretch pb-3">
  					<div id="cartEmptyItems" className="p-3 text-center mx-auto h-auto py-4" style={cartCount > 0 ? {display: 'none'} : {display: 'block'}}>
  						<h1 className="font-weight-bold line-height-smaller f-40 mb-0">Dein Warenkorb</h1>
  						<p className="mw-300 mx-auto line-height-smaller mt-3 mb-5" style={{fontSize: '19px', lineHeight: '25px'}}>Dein Warenkorb ist noch leer. Jetzt füllen und noch kurz die Welt retten.</p>
  						<div className="row justify-content-center text-left mw-500 px-md-3">
  							<div className="col-sm-12 mt-0 px-2 py-1">
  								<div className="border-light d-flex align-items-center justify-content-between p-0 bg-white">
  									<div className="d-flex align-items-center justify-content-start">
  										<div className="imgContainer w-100 h-100 d-block">
  											<span className="w-100">
                          <GatsbyImage className={`${styles.oCover}`} image={data.wFluorid.childImageSharp.gatsbyImageData} alt="Probierpaket mit Fluorid" />
  											</span>
  										</div>
  										<div className="copy pr-2 py-2 pl-3">
  											<span>
  												<p className="mb-0 line-height-smaller f-17 f-md-20">Probierpaket <b>mit</b> Fluorid</p>
  											</span>
  											<p className="f-15 mb-0">8,95€
  												<s className="o-5 ml-1">11,95€</s>
  											</p>
  										</div>
  									</div>
  									<div className="pr-3 py-3 pl-2">
  										<a href="/#" className="btn btn-primary btn-sm f-15 atc-btn emptyCartATC" onClick={handleAddToCart} data-productid="21372384123722">Hinzufügen</a>
  									</div>
  								</div>
  							</div>
  						</div>
  					</div>
  					<div id="cartListItems" style={cartCount > 0 ? {display: 'block'} : {display: 'none'}}>
              {cartItems.map((lineItem) => (
                <div className="bg-white mt-0 position-relative p-2 p-md-3 border-top-list cart-item" data-id={lineItem.variant.id} key={lineItem}>
                  {((!hasFFinCart && lineItem.variant.product.id === "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2MTkxNzYzNzAzNTk=") || lineItem.variant.product.id !== "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzY2MTkxNzYzNzAzNTk=") &&
                    <RemoveCartItem removeItemFromBonusArray={removeItemFromBonusArray} itemId={lineItem.variant.id} productId={lineItem.variant.product.id} />
                  }
                  <div className="d-flex align-items-stretch justify-content-between z-1 position-relative">
                    <div className="d-flex w-100 align-items-center">
                      <div className="px-0 h-auto align-self-stretch d-flex align-items-center product-img">
                        <span className="w-100 d-block position-relative">
                          <img className={`w-100 ${styles.oCover}`} src={lineItem.variant.image.src} style={{width: '100px', height: '100px'}} alt={lineItem.title} />
                        </span>
                      </div>
                      <div className="w-100 h-auto align-content-stretch pr-0 p-2 px-md-3 pt-md-0 pb-md-1 d-flex flex-column justify-content-between h-100">
                        <span className="mb-0">
                          <p className={`f-17 ${styles.productTitle} line-height-smaller d-block text-black mb-1`}>{lineItem.title}</p>
                        </span>
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex flex-column justify-content-between">
                            <div className="mb-0 f-15 line-height-smaller">
                              <span className={`d-block mb-2 text-uppercase f-12 text-mint o-7 ${lineItem.variant.title !== 'Default Title' ? 'visiblile' : 'invisible'}`}>{lineItem.variant.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDU4MzMzNDM5NjA4Nw==' || lineItem.variant.id === 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDU4MzM1MzM2ODc1OQ==' ? '2x 20 Drops • '+lineItem.variant.title : lineItem.variant.title}</span>
                            </div>
                            <div id="quantities" className={styles.quantitySelect}>
                              <div className="d-flex align-items-center justify-content-start">
                                <QuantityStepper itemId={lineItem.variant.id} quantity={lineItem.quantity} />
                              </div>
                            </div>
                          </div>
                          <div className="text-right pb-0 mb-0">
                            <p className={`mb-0 f-20 text-right line-height-smaller ${styles.priceString}`}>
                              {(() => {
                                if (lineItem.variant.compareAtPrice !== null) {
                                  if (lineItem.discountAllocations.length !== 0) {
                                    return (
                                      <>
                                      {lineItem.variant.compareAtPrice !== "0.00"
                                        ? <span className="small"><s>{(parseFloat(lineItem.variant.compareAtPrice)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</s></span>
                                        : null
                                      }
                                      <span className="d-block text-mint">{lineItem.discountAllocations.length !== 0 ? ((lineItem.variant.price*(1-cart.discountApplications[0].value.percentage/100))*lineItem.quantity).toFixed(2).replace('.', ',') : (parseFloat(lineItem.variant.price)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</span>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <>
                                      {lineItem.variant.compareAtPrice !== "0.00"
                                        ? <span className="small"><s>{(parseFloat(lineItem.variant.compareAtPrice)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</s></span>
                                        : null
                                      }
                                      <span className="d-block text-mint">{(parseFloat(lineItem.variant.price)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</span>
                                      </>
                                    )
                                  }
                                } else if (lineItem.discountAllocations.length !== 0) {
                                  return (
                                    <>
                                    <span className="small"><s>{(parseFloat(lineItem.variant.price)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</s></span>
                                    <span className="d-block text-mint">{lineItem.discountAllocations.length !== 0 ? ((lineItem.variant.price*(1-cart.discountApplications[0].value.percentage/100))*lineItem.quantity).toFixed(2).replace('.', ',') : (parseFloat(lineItem.variant.price)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</span>
                                    </>
                                  )
                                } else {
                                  return (
                                    <span className="d-block text-mint">{(parseFloat(lineItem.variant.price)*lineItem.quantity).toFixed(2).toString().replace('.', ',')}€</span>
                                  )
                                }
                              })()}
                              {lineItem.variant.unitPrice !== null
                                ? <><span className="small grey" style={{fontSize: '11px'}}>{localStorage.getItem('discount') && lineItem.variant.title.includes('4 Monate') ? '0,05' : lineItem.variant.unitPrice.amount.replace('.',',')}€ / Stück</span></>
                                : null
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
  					</div>
            {(cartCount > 0) &&
              <div className={styles.upsellContainer}>
                <div className={styles.upsellHeader}>
                  {!allUpsellItemsAdded
                    ? <><h4>Hole dir jetzt zusätzlich unser<br />Family & Friends Paket für 50% Rabatt<br /><span className="small grey">Zahle 1, Erhalte 2</span></h4></>
                    : null
                  }
                </div>
                <div className={styles.upsellContent}>
                  {data.allShopifyProduct.edges.map((product, productKey) => (
                    !cartVariantIds.includes(product.node.storefrontId)
                    ? (<CartUpsellItem product={product} productKey={productKey} />)
                    : null
                  ))}
                </div>
              </div>
            }
            {(cartCount > 0 && data.singleItems.nodes.length > allBonusItems.length) &&
              <div className={styles.productSlider}>
                <p className={styles.productSliderTitle}>Vervollständige deine Bestellung</p>
                <Swiper {...params} pagination={false} navigation={true} className={styles.slider}>
                  {data.singleItems.nodes.map((product, productKey) => (
                    !allBonusItems.includes(product.storefrontId)
                    ? <SwiperSlide key={productKey} className={styles.sliderSlide}>
                        <div className={styles.sliderSlideOuter}>
                          <div className={styles.slideContent} onClick={handleZubehoerClick} onKeyDown={null}>
                            <div className={styles.productImg}>
                              <GatsbyImage className={`${styles.oCover} ${styles.img}`} image={product.images[0].localFile.childImageSharp.gatsbyImageData} alt={product.title} />
                            </div>
                            <div className={styles.productContent}>
                              <p className={styles.productTitleBold}>{product.title}</p>
                              <p> {product.variants[0].price.replace('.',',')}€ {product.variants[0].compareAtPrice !== "0.00" ? <s>{product.variants[0].compareAtPrice.replace('.',',')}€</s> : null}</p>
                            </div>
                          </div>
                          <div className={styles.slideAction}>
                            <a href="#" onClick={handleAddToCartBonusItem} data-id={product.variants[0].shopifyId} data-productid={product.storefrontId}>Hinzufügen</a>
                          </div>
                        </div>
                      </SwiperSlide>
                    : null
                  ))}
                </Swiper>
              </div>
            }
  				</div>
  				<div id="cartFooter" className="bg-white h-auto border-top mt-auto w-100 mx-auto" style={cartCount > 0 ? {display: 'block'} : {display: 'none'}}>
  					<div className="px-2 pt-2 px-md-3 pt-md-3 pb-3 d-block mt-auto f-17 f-md-20 bottomCheckout">
  						<div>
  							<div className="pt-2 d-block py-2 d-block">
  								<div className="d-flex align-items-center justify-content-center px-2">
  									<div id="subTotal" className="text-center line-height-smaller align-items-center justify-content-center">
  										<div className="d-block pr-3">
  											<span className="d-block f-11 text-uppercase grey">Summe</span>
  											<span id="subtotal" className="f-20">{cart !== null ? cart.totalPrice.replace(".", ",") : '0'}€</span>
  										</div>
  									</div>
  									<button className="d-inline-block btn btn-primary f-20 btn-lg checkout_button w-100" name="checkout" type="submit" onClick={handleCheckoutBtn}>
  										<FontAwesomeIcon icon={faLock} className="small mr-1 f-17" /> Zur Kasse
  									</button>
  								</div>
  							</div>
  						</div>
  					</div>
  				</div>
  			</form>
  		</div>
  	</div>
  )
}
