// extracted by mini-css-extract-plugin
export var nav = "navbar-module--nav--EDlqC";
export var navLeftPart = "navbar-module--nav-left-part--yka0w";
export var navRightPart = "navbar-module--nav-right-part--XpcPI";
export var navItem = "navbar-module--nav-item--ATsz2";
export var desktopNav = "navbar-module--desktop-nav---snR5";
export var navActive = "navbar-module--nav-active--eUgSd";
export var navLight = "navbar-module--nav-light--fPlaj";
export var logoLink = "navbar-module--logo-link--jiH0X";
export var fullCart = "navbar-module--full-cart--ly1Zq";
export var scrolled = "navbar-module--scrolled--FCL+T";