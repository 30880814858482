// extracted by mini-css-extract-plugin
export var cartOverlay = "cartOverlay-module--cart-overlay--U3FZ-";
export var shoppingCart = "cartOverlay-module--shopping-cart--WjvGV";
export var cartNav = "cartOverlay-module--cart-nav--35owe";
export var menuBackButton = "cartOverlay-module--menu-back-button--YY4Of";
export var cartContent = "cartOverlay-module--cart-content--3kXPo";
export var cartEmptyItems = "cartOverlay-module--cart-empty-items--KWfZt";
export var imgContainer = "cartOverlay-module--img-container--H9JH4";
export var trashIcon = "cartOverlay-module--trash-icon--k3rgj";
export var productImg = "cartOverlay-module--product-img--ruX8u";
export var quantities = "cartOverlay-module--quantities--5ve3j";
export var cartFooter = "cartOverlay-module--cart-footer--YHw2x";
export var bottomCheckout = "cartOverlay-module--bottom-checkout--0xMxd";
export var oCover = "cartOverlay-module--o-cover--RDotW";
export var productTitle = "cartOverlay-module--product-title--Ep2ln";
export var quantitySelect = "cartOverlay-module--quantity-select--me2NZ";
export var isCartOpen = "cartOverlay-module--is-cart-open--TcGEW";
export var upsellContainer = "cartOverlay-module--upsell-container--xKA6m";
export var upsellHeader = "cartOverlay-module--upsell-header--5g2wo";
export var upsellContent = "cartOverlay-module--upsell-content--gF01X";
export var productSlider = "cartOverlay-module--product-slider--e5NnO";
export var productSliderTitle = "cartOverlay-module--product-slider-title--qQ5+v";
export var swiperNavigationPrev = "cartOverlay-module--swiper-navigation-prev--KDaiU";
export var slider = "cartOverlay-module--slider--Sx+09";
export var sliderSlide = "cartOverlay-module--slider-slide--Qig7L";
export var sliderSlideOuter = "cartOverlay-module--slider-slide-outer--A9k9Z";
export var slideContent = "cartOverlay-module--slide-content--fAQzV";
export var productContent = "cartOverlay-module--product-content--e6s0v";
export var productTitleBold = "cartOverlay-module--product-title-bold--a5j9b";
export var slideAction = "cartOverlay-module--slide-action--zGIMV";
export var priceString = "cartOverlay-module--price-string--mMgLW";
export var img = "cartOverlay-module--img--CLQju";