import React, { useState, useEffect } from 'react';

import * as styles from "./navbar.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { useCartCount } from '@jamal./gatsby-theme-shopify-manager';
import useIsClient from '../../useIsClient';

import menuBtn from '../../images/menu-rounded-solid.svg';
import menuBtnWhite from '../../images/menu-rounded-solid-white.svg';

export default function Navbar(props) {

  const { isClient, key } = useIsClient();

  const cartCount = useCartCount()

  const [offset, setOffset] = useState(0);
  const [showCart, setShowCart] = useState(false);

  useEffect(() => {
    // if (isDesktop) {
      const handleScroll = () => {
        setOffset(window.scrollY/150);
      };

      document.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        // clean up the event handler when the component unmounts
        document.removeEventListener('scroll', handleScroll);
      };
    // }
  }, [offset]);

  function handleCartClick(event) {
    event.preventDefault()

    if (!showCart) {
      // show cart and change state
      setShowCart(true);
    }
    document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');

    document.body.classList.add("body-lock");
    document.getElementById("cartOverlay").classList.toggle('is-cart-open');
  }

  function handleHideCart() {
    document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');
    document.getElementById("cartOverlay").classList.toggle('is-cart-open');
    document.body.classList.remove("body-lock");

    setShowCart(false);
  }

  const data = useStaticQuery(graphql`
    query {
      logoImgBlack: file(relativePath: { eq: "drops-logo-black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 101, height: 40, placeholder: NONE)
        }
      },
      cartImgBlack: file(relativePath: { eq: "cart-icon-black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 35, height: 35, placeholder: NONE)
        }
      },
      logoImgWhite: file(relativePath: { eq: "drops-logo-white.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 101, height: 40, placeholder: NONE)
        }
      },
      cartImgWhite: file(relativePath: { eq: "cart-icon-white.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 35, height: 35, placeholder: NONE)
        }
      },
    }
  `)

  if ( !isClient ) return null;
  return (
    <div id="app" className="index" style={{zIndex: '2',background: 'white'}} key={key}>
      <div aria-hidden="true" className="position-fixed p-fill z-1-1 cartContentBG" onClick={handleHideCart}>
        <div className="h-100 w-100 o-9 backgroundBlackTransparent"></div>
      </div>
      <div id="header">
        <div className="position-fixed w-100" style={{top: '-1px', zIndex: '200'}}>
          <div className="position-relative">
            <nav id={styles.nav} className="w-100 d-block navbar-expand-lg" data-options="scrolltop:false;" style={offset <= 1.1 ? ((offset >= 1.0) ? {boxShadow: 'rgba(0, 0, 0, 0.04) 0px 1px 1px 0px, rgba(0, 0, 0, 0.04) 0px 2px 2px 0px, rgba(0, 0, 0, 0.04) 0px 4px 4px 0px, rgba(0, 0, 0, 0.04) 0px 8px 8px 0px, rgba(0, 0, 0, 0.04) 0px 16px 16px 0px', backgroundColor: `rgba(255, 255, 255, ${offset})`} : {boxShadow: 'rgba(0, 0, 0, 0) 0px 1px 1px 0px, rgba(0, 0, 0, 0) 0px 2px 2px 0px, rgba(0, 0, 0, 0) 0px 4px 4px 0px, rgba(0, 0, 0, 0) 0px 8px 8px 0px, rgba(0, 0, 0, 0) 0px 16px 16px 0px', backgroundColor: `rgba(255, 255, 255, ${offset})`}) : {boxShadow: 'rgba(0, 0, 0, 0.04) 0px 1px 1px 0px, rgba(0, 0, 0, 0.04) 0px 2px 2px 0px, rgba(0, 0, 0, 0.04) 0px 4px 4px 0px, rgba(0, 0, 0, 0.04) 0px 8px 8px 0px, rgba(0, 0, 0, 0.04) 0px 16px 16px 0px', backgroundColor: 'rgba(255, 255, 255, 1)'}}>
              <div className="align-items-center w-100 d-flex justify-content-between">
                <div className={`align-self-center text-left col-4 col-md-5 ${styles.navLeftPart}`}>
                  <div className="d-flex text-left text-uppercase font-weight-bold justify-content-start">
                    <div className="navbar-toggler" style={{paddingLeft: '0'}}>
                      <a href="/#" onClick={props.toggleMobile}>
                        <img style={{padding: '0'}} width="25" height="25" src={props.navStyle === 'light' && offset < 0.9 ? menuBtnWhite : menuBtn} alt="Mobile Menu" />
                      </a>
                    </div>
                    <div className={`collapse navbar-collapse ${styles.desktopNav}`}>
                      <a href="/" className={`mx-3 text-uppercase ${window.location.pathname === '/' ? styles.navActive : null} ${props.navStyle === 'light' && offset < 0.9 ? styles.navLight : null}`}>Shop</a>
                    </div>
                    <div className={`collapse navbar-collapse ${styles.desktopNav}`}>
                      <a href="/products/probierpaket" className={`mx-3 text-uppercase ${window.location.pathname.includes('/products/probierpaket') ? styles.navActive : null} ${props.navStyle === 'light' && offset < 0.9 ? styles.navLight : null}`}>Probierpaket</a>
                    </div>
                    <div className={`collapse navbar-collapse ${styles.desktopNav}`}>
                      <a href="/products/monatspaket" className={`mx-3 text-uppercase ${window.location.pathname.includes('/products/monatspaket') ? styles.navActive : null} ${props.navStyle === 'light' && offset < 0.9 ? styles.navLight : null}`}>Monatspaket</a>
                    </div>
                  </div>
                </div>
                <a href="/" className={`text-center col-4 col-md-2 ${styles.logoLink}`}>
                  <GatsbyImage loading="eager" id="nav-logo" className="logo-img" style={{maxHeight: '30px'}} image={props.navStyle === 'light' && offset < 0.9 ? data.logoImgWhite.childImageSharp.gatsbyImageData : data.logoImgBlack.childImageSharp.gatsbyImageData} alt="Drops Logo" />
                </a>
                <div className={`text-right d-flex align-items-flex-end col-4 col-md-5 ${styles.navRightPart}`}>
                  <div className="d-flex text-right justify-content-end w-100 font-weight-bold">
                    <div className={`collapse navbar-collapse ${styles.desktopNav}`}>
                      <a href="/pages/aboutus" className={`mx-3 text-uppercase ${window.location.pathname.includes('/pages/aboutus') ? styles.navActive : null} ${props.navStyle === 'light' && offset < 0.9 ? styles.navLight : null}`}>Über Uns</a>
                    </div>
                    <div className={`collapse navbar-collapse ${styles.desktopNav}`}>
                      <a href="/reviews" className={`mx-3 text-uppercase ${window.location.pathname.includes('/reviews') ? styles.navActive : null} ${props.navStyle === 'light' && offset < 0.9 ? styles.navLight : null}`}>Bewertungen</a>
                    </div>
                    <a href="#/" onClick={handleCartClick} className={`d-inline-block font-weight-normal pr-3 ${styles.navItem} cartIcon position-relative`} data-count={cartCount}>
                      <GatsbyImage loading="eager" className="cartIcon" alt={`${cartCount} Items in Cart`} image={props.navStyle === 'light' && offset < 0.9 ? data.cartImgWhite.childImageSharp.gatsbyImageData : data.cartImgBlack.childImageSharp.gatsbyImageData} />
                      <div className={`mr-0 d-inline-flex position-absolute ${styles.fullCart}`} style={cartCount > 0 ? {visibility: 'visible'} : {visibility: 'hidden'}} data-count={cartCount}>
                        <div className="mx-auto d-flex align-items-center justify-content-center white f-13"></div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}
