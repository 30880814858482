import React, { useState } from "react"

import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./cartUpsellItem.module.scss"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useAddItemToCart } from '@jamal./gatsby-theme-shopify-manager';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const CartUpsellItem = ({ product, productKey }) => {

  const addItemToCart = useAddItemToCart();
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [activeVariant, setActiveVariant] = useState(product.node.variants[0].shopifyId);
  const [activeVariantName, setActiveVariantName] = useState(product.node.variants[0].title);

  function toggleUpsellDetails(event) {
    setDetailsOpen(!detailsOpen)
  }

  function handleVariantChange(event) {
    setActiveVariant(event.currentTarget.dataset.value);
    setActiveVariantName(event.currentTarget.dataset.title);
  }

  async function handleAddToCartUpsell(event) {
    event.preventDefault()

    try {
      await addItemToCart(activeVariant, 1, [{
        key: '_type',
        value: 'upsell',
      }]);
    } catch (error) {
      alert(error);
    }
  }

  const options = {
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showthumbnailsButton: false
    },
    thumbnails: {
      showThumbnails: false
    },
    caption: {
      showCaption: false
    },
    settings: {
      usingPreact: true,
    },
  }

  return (
    <div className={styles.upsellBox}>
      <div className={`${styles.innerBox} bg-white mt-0 position-relative cart-item`} data-id={product.node.variants[0].shopifyId} key={product}>
        <div className="d-flex align-items-stretch justify-content-between z-1 position-relative">
          <div className="d-flex w-100 align-items-start">
            <div className="px-0 h-auto align-content-stretch d-flex align-items-center product-img">
              <span className="w-100 d-block position-relative">
                <GatsbyImage image={product.node.images[0].localFile.childImageSharp.gatsbyImageData}
                  loading="eager"
                  className={`${styles.oCover}`}
                  key={productKey}
                  alt={`${product.node.title} ${productKey}`}
                />
              </span>
            </div>
            <div className="w-100 h-auto align-content-stretch pr-0 p-2 px-md-3 pt-md-2 pb-md-1 d-flex flex-column">
              <div className="d-flex justify-content-between align-items-start mb-1">
                <div>
                  <span className="mb-0">
                    <p className={`${styles.productTitle} f-17 line-height-smaller d-block text-black mb-1`}>{product.node.title}</p>
                  </span>
                  <div className="mb-0 f-15 line-height-smaller">
                    <span className={`d-block mb-2 text-uppercase f-12 text-mint o-7 ${activeVariantName !== 'Default Title' ? 'visible' : 'invisible'}`}>{activeVariantName}</span>
                  </div>
                </div>
                <div className={styles.priceInfo}>
                  <div className="text-right pb-0 mb-0">
                    <p className="mb-0 f-20 text-right line-height-smaller">
                      {(() => {
                        if (product.node.variants[0].compareAtPrice !== null && product.node.variants[0].compareAtPrice > 0 && product.node.variants.length === 1) {
                          return (
                            <span className="small"><s>{product.node.variants[0].compareAtPrice.replace('.', ',')}€</s></span>
                          )
                        } else if (product.node.variants[0].compareAtPrice !== null && product.node.variants[0].compareAtPrice > 0 && product.node.variants.length > 1) {
                          return (
                            <span className="small"><s>{product.node.variants[0].compareAtPrice.replace('.', ',')}€</s></span>
                          )
                        } else {
                          return
                        }
                      })()}
                      <span className="d-block text-mint">{parseFloat(product.node.priceRangeV2.maxVariantPrice.amount).toFixed(2).toString().replace('.', ',')}€</span>
                      <span className="small grey" style={{fontSize: '11px'}}>{(product.node.priceRangeV2.maxVariantPrice.amount/40).toFixed(2).replace('.',',')}€ / Stück</span>
                    </p>
                  </div>
                </div>
              </div>
              {product.node.variants.length > 1 &&
              <div className={`${styles.swatch}`}>
                {product.node.variants.map((variant, key) => (
                  <div role="button" tabIndex={key} className={`${styles.swatchElementText} ${variant.shopifyId === activeVariant ? styles.checked : null}`} data-key={key} data-value={variant.shopifyId} data-title={variant.title} onFocus={ () => void 0 } onBlur={ () => void 0 } onClick={handleVariantChange} onKeyDown={null}>{variant.title}</div>
                ))}
              </div>
              }

            </div>

          </div>
        </div>
        <div className={`d-flex justify-content-between align-items-center pb-3 px-2 ${styles.upsellInfoRow}`}>
          <div className={styles.upsellInfo}>
            <div className={styles.detailed} role="button" tabIndex={0} onClick={toggleUpsellDetails} onKeyDown={null}>Weitere Infos {detailsOpen ? '-' : '+'}</div>
          </div>
          <div className="text-right pb-0 mb-0">
            <button className={`${styles.addBtn} d-inline-block btn btn-primary btn-lg w-100`} onClick={handleAddToCartUpsell} onKeyDown={null}>
              Hinzufügen
            </button>
          </div>
        </div>
      </div>
      <div className={`${styles.productInfoBox} ${detailsOpen ? styles.infoIsOpen : styles.infoIsClosed}`}>
        <div className={styles.infoInner}>
          <div className={styles.infoBody}>
            <h6>Beschreibung</h6>
              <div dangerouslySetInnerHTML={{ __html: product.node.descriptionHtml }}></div>
              {product.node.images.length > 1
              ? <>
              <h6>Weitere Bilder</h6>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <Row>
                  {product.node.images.map((image, key) => (
                    <Col>
                      <GatsbyImage
                      className={`d-md-block ${styles.oCover} m-0 mb-2`}
                      image={image.localFile.childImageSharp.gatsbyImageData}
                      loading="eager"
                      key={image.id}
                      alt={`${product.node.title}`}
                      style={{height: '100px', width: '100px'}}
                      />
                    </Col>
                  ))}
                </Row>
                </SRLWrapper>
              </SimpleReactLightbox>
              </>
              : null }
          </div>
         </div>
      </div>
    </div>
  )
}

export default CartUpsellItem
