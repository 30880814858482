// extracted by mini-css-extract-plugin
export var upsellBox = "cartUpsellItem-module--upsell-box--Zeq9y";
export var innerBox = "cartUpsellItem-module--inner-box--UOwUb";
export var oCover = "cartUpsellItem-module--o-cover--hOkkt";
export var productTitle = "cartUpsellItem-module--product-title--Vifu4";
export var priceInfo = "cartUpsellItem-module--price-info--PMTbz";
export var swatch = "cartUpsellItem-module--swatch--MwwBO";
export var swatchElement = "cartUpsellItem-module--swatch-element--Yboyw";
export var checked = "cartUpsellItem-module--checked--qC+bh";
export var swatchElementText = "cartUpsellItem-module--swatch-element-text--8epta";
export var upsellInfoRow = "cartUpsellItem-module--upsell-info-row---ALj0";
export var upsellInfo = "cartUpsellItem-module--upsell-info--Advyr";
export var detailed = "cartUpsellItem-module--detailed--dE-9R";
export var addBtn = "cartUpsellItem-module--add-btn--KfO4b";
export var productInfoBox = "cartUpsellItem-module--product-info-box--NDJly";
export var infoInner = "cartUpsellItem-module--info-inner--Y9p88";
export var infoHeader = "cartUpsellItem-module--info-header--zm1Vk";
export var infoBody = "cartUpsellItem-module--info-body--gzBU9";
export var infoIsClosed = "cartUpsellItem-module--info-is-closed--pndKI";
export var infoIsOpen = "cartUpsellItem-module--info-is-open--7P-9Z";