import React, { useState, useEffect, useLayoutEffect } from "react"

import Navbar from "./navbar"
import Footer from "./footer"
import BottomBar from "./bottomBar"
import CartOverlay from "../cart/cartOverlay"
import MobileNavigationOverlay from "./mobileNavigationOverlay"
import { useAddDiscountToCart, useCart, useSetCheckoutNote } from '@jamal./gatsby-theme-shopify-manager';

import useIsClient from '../../useIsClient';

export default function Layout({ showBottom, activeVariant, containerItems, children, navStyle }) {

  const { isClient, key } = useIsClient();

  const [showMobileNavi, setShowMobileNavi] = useState(false);
  const [cartLoaded, setCartLoaded] = useState(false);
  // const [optimizeExperiment, setOptimizeExperiment] = useState(null);
  const addDiscountToCart = useAddDiscountToCart();
  const cart = useCart();
  const setNote = useSetCheckoutNote();

  useEffect(() => {
    if (!cartLoaded && cart !== null) {
      setCartLoaded(true);
      const params = new URLSearchParams(window.location.search);
      if (params.get('utm_medium') !== null) {
        setNote(params.get('utm_medium')+','+params.get('utm_content'))
        localStorage.setItem("origin", JSON.stringify(params.get('utm_medium')+','+params.get('utm_content')));
      }
      if (params.get("discount") !== null && localStorage.getItem("discount") === null) {
        localStorage.setItem("discount", params.get("discount"));
        addDiscountToCart(params.get("discount"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartLoaded, cart]);

  // useEffect(() => {
  //   if (optimizeExperiment !== null) {
  //     const params = new URLSearchParams(window.location.search);
  //     setNote(params.get('utm_medium')+','+params.get('utm_content')+','+(optimizeExperiment !== null ? 'experiment_'+optimizeExperiment : ''))
  //     localStorage.setItem("origin", JSON.stringify(params.get('utm_medium')+','+params.get('utm_content')+','+(optimizeExperiment !== null ? 'experiment_'+optimizeExperiment : '')));
  //   }
  // }, [optimizeExperiment])
  //
  // useEffect(() => {
  //   window.dataLayer = window.dataLayer || []
  //   window.dataLayer.push({ event: 'optimize.activate' })
  //   if (window.gaData["UA-138870810-6"].experiments !== undefined) {
  //     setOptimizeExperiment(window.gaData["UA-138870810-6"].experiments.VuIC_sYpSsSEDo1OKJj50Q)
  //   } else {
  //     setOptimizeExperiment(null)
  //   }
  // }, [])

  // useLayoutEffect(() => {
  //   console.log("layout effect");
  //   console.log(cart);
  //   if (cart !== null) {
  //     const params = new URLSearchParams(window.location.search);
  //     const discount = params.get("discount");
  //     if (discount !== null) {
  //       localStorage.setItem("discount", discount);
  //       console.log("add discount: "+discount);
  //       addDiscountToCart(discount);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  function toggleMobileMenu(event) {
    event.preventDefault()

    setShowMobileNavi(!showMobileNavi);
    document.body.classList.toggle("body-lock");
  }

  if ( !isClient ) return null;

  return (
    <div key={key}>
      <Navbar toggleMobile={toggleMobileMenu} navStyle={navStyle} />
      <div id="main" className={`${window.location.pathname.includes('products') ? 'product' : 'index'}`}>
        <div>
          { children }
        </div>
        <Footer showBottom={showBottom !== 'false' ? 'true' : 'false'} />
      </div>
      {showBottom !== 'false' &&
        <BottomBar activeVariant={activeVariant} containerItems={containerItems} />
      }
      <CartOverlay />
      <MobileNavigationOverlay showMobileNavi={showMobileNavi} toggleMobile={toggleMobileMenu} />
    </div>
  )
}
