import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFacebookSquare,
  faInstagramSquare,
  faCcAmex,
  faCcApplePay,
  faCcMastercard,
  faCcVisa
} from '@fortawesome/free-brands-svg-icons'

export default function Footer(props) {
  const data = useStaticQuery(graphql`
    query {
      logoImg: file(relativePath: { eq: "drops-logo-black.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 101, height: 40, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <div className="position-relative">
			<footer className="site-footer cream pb-7" style={{ marginBottom: (props.showBottom === 'false' ? '0' : '91px')}}>
				<div className="container">
					<div className="row">
            <div className="col-sm-6 col-lg-3 mb-5">
  						<GatsbyImage className="logo-img" image={data.logoImg.childImageSharp.gatsbyImageData} style={{maxHeight: '40px', marginLeft: '0px'}} alt="Drops Logo" />
  					</div>
						<div className="col-sm-6 col-lg-3 mb-3">
							<h4>Unternehmen</h4>
							<ul className="site-footer__links" style={{listStyle : 'none'}}>
								<li><a className="font-weight-normal" href="/pages/imprint">Impressum</a></li>
								<li><a className="font-weight-normal" href="/pages/privacy-policy">Datenschutz</a></li>
								<li><a className="font-weight-normal" href="/pages/agb">AGBs</a></li>
                <li><a className="font-weight-normal" href="/pages/disclaimer">Widerruf</a></li>
							</ul>
						</div>
						<div className="col-sm-6 col-lg-3 mb-3">
			        <h4>Folge Uns</h4>
			        <ul className="inline-list social-icons">
						    <li className="ml-0">
						      <a className="icon-fallback-text" href="https://www.facebook.com/Toothpaste-Drops-105838258154337/" target="_blank" rel="noreferrer" title="Drops on Facebook">
                    <FontAwesomeIcon icon={faFacebookSquare} />
						      </a>
						    </li>
						    <li>
						      <a className="icon-fallback-text" href="https://www.instagram.com/toothpaste.drops" target="_blank" rel="noreferrer" title="Drops on Instagram">
                    <FontAwesomeIcon icon={faInstagramSquare} />
						      </a>
						    </li>
							</ul>
			      </div>
						<div className="col-sm-6 col-lg-3 mb-3">
			        <h4>Kontakt</h4>
			        <div className="rte"><p>E-Mail: <a href="mailto:info@toothpastedrops.com">info@toothpastedrops.com</a></p></div>
              <div className="rte"><p>WhatsApp: <a href="https://wa.me/4915735988383">+49157 / 35988383</a></p></div>
			      </div>
					</div>
					<hr className="d-block d-lg-none mb-5" />
					<p className="site-footer__links text-center">Copyright © 2021, <a href="/" title="">Drops</a>.</p>
					<div className="grid">
			      <div className="grid__item text-center">
			        <ul className="inline-list payment-icons">
			          <li>
			            <span className="icon-fallback-text">
			              <FontAwesomeIcon icon={faCcAmex} />
			              <span className="fallback-text">american express</span>
			            </span>
			          </li>
			          <li>
			            <span className="icon-fallback-text">
			              <FontAwesomeIcon icon={faCcApplePay} />
			              <span className="fallback-text">apple pay</span>
			            </span>
			          </li>
			          <li>
			            <span className="icon-fallback-text">
			              <FontAwesomeIcon icon={faCcMastercard} />
			              <span className="fallback-text">master</span>
			            </span>
			          </li>
			          <li>
			            <span className="icon-fallback-text">
			              <FontAwesomeIcon icon={faCcVisa} />
			              <span className="fallback-text">visa</span>
			            </span>
			          </li>
			        </ul>
			        <div className="my-2 f-15 font-weight-normal" style={{fontWeight : '400!important'}}>
								<a className="mx-3 font-weight-normal gray6" href="/pages/privacy-policy">Datenschutz</a>
								<a className="mx-3 font-weight-normal gray6" href="/pages/agb">AGB</a>
			        </div>
			      </div>
			    </div>
				</div>
			</footer>
		</div>
  )
}
